import { useEffect, useState } from "react";
import "./ticker.css";

const Ticker = ({ text }) => {
  const [animationDuration, setAnimationDuration] = useState(20); // Default speed

  useEffect(() => {
    const textLength = text.length;
    const newDuration = Math.max(20, textLength / 5); // Adjusts speed dynamically
    setAnimationDuration(newDuration);
  }, [text]);

  return (
      <div className="ticker-container">
        <div
            className="ticker"
            style={{ animationDuration: `${animationDuration}s` }}
        >
          {text}
        </div>
      </div>
  );
};

export default Ticker;
