export const monitoringNDDFields = [
  {
    id: "monitorBy",
    name: "monitorBy",
    label: "Monitoring of the NDD Program by",
    placeholder: "Enter monitor by",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "National Monitor",
        value: "National Monitor",
      },
      {
        label: "State Monitor",
        value: "State Monitor",
      },
      {
        label: "District Monitor",
        value: "District Monitor",
      },
    ],
  },
  {
    id: "nameMonitorOfficer",
    name: "nameMonitorOfficer",
    label: "Name of Monitoring Officer",
    placeholder: "Enter name of monitoring officer",
    required: true,
    type: "text",
  },
  {
    id: "designationMonitorOfficer",
    name: "designationMonitorOfficer",
    label: "Designation of Monitoring Officer",
    placeholder: "Enter designation of monitoring officer",
    required: true,
    type: "text",
  },
  {
    id: "deptMonitorOfficer",
    name: "deptMonitorOfficer",
    label: "Department of Monitoring Officer",
    placeholder: "Enter department of monitoring officer",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Health",
        value: "Health",
      },
      {
        label: "Education",
        value: "Education",
      },
      {
        label: "WCD",
        value: "WCD",
      },
      {
        label: "Others",
        value: "Others",
      },
    ],
  },
  {
    id: "deptMonitorOfficer-Others",
    name: "deptMonitorOfficer-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.deptMonitorOfficer === "Others",
  },
  {
    id: "mobileMonitorOfficer",
    name: "mobileMonitorOfficer",
    label: "Mobile Number of Monitoring Officer",
    placeholder: "Enter mobile number of monitoring officer",
    required: true,
    type: "number",
    minLength: 10,
    maxLength: 10,
  },
  {
    id: "state",
    name: "state",
    label: "State/UT",
    placeholder: "Enter state",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands",
      },
      {
        label: "Andhra Pradesh",
        value: "Andhra Pradesh",
      },
      {
        label: "Arunachal Pradesh",
        value: "Arunachal Pradesh",
      },
      {
        label: "Assam",
        value: "Assam",
      },
      {
        label: "Bihar",
        value: "Bihar",
      },
      {
        label: "Chhattisgarh",
        value: "Chhattisgarh",
      },
      {
        label: "Chandigarh",
        value: "Chandigarh",
      },
      {
        label: "Delhi",
        value: "Delhi",
      },
      {
        label: "Dadra & Nagar Haveli and Daman & Diu",
        value: "Dadra & Nagar Haveli and Daman & Diu",
      },
      {
        label: "Goa",
        value: "Goa",
      },
      {
        label: "Gujarat",
        value: "Gujarat",
      },
      {
        label: "Haryana",
        value: "Haryana",
      },
      {
        label: "Himachal Pradesh",
        value: "Himachal Pradesh",
      },
      {
        label: "Jharkhand",
        value: "Jharkhand",
      },
      {
        label: "Jammu & Kashmir",
        value: "Jammu & Kashmir",
      },
      {
        label: "Kerala",
        value: "Kerala",
      },
      {
        label: "Karnataka",
        value: "Karnataka",
      },
      {
        label: "Ladakh",
        value: "Ladakh",
      },
      {
        label: "Lakshadweep",
        value: "Lakshadweep",
      },
      {
        label: "Manipur",
        value: "Manipur",
      },
      {
        label: "Maharashtra",
        value: "Maharashtra",
      },
      {
        label: "Madhya Pradesh",
        value: "Madhya Pradesh",
      },
      {
        label: "Mizoram",
        value: "Mizoram",
      },
      {
        label: "Meghalaya",
        value: "Meghalaya",
      },
      {
        label: "Nagaland",
        value: "Nagaland",
      },
      {
        label: "Odisha",
        value: "Odisha",
      },
      {
        label: "Punjab",
        value: "Punjab",
      },
      {
        label: "Puducherry",
        value: "Puducherry",
      },
      {
        label: "Rajasthan",
        value: "Rajasthan",
      },
      {
        label: "Sikkim",
        value: "Sikkim",
      },
      {
        label: "Tamil Nadu",
        value: "Tamil Nadu",
      },
      {
        label: "Telangana",
        value: "Telangana",
      },
      {
        label: "Tripura",
        value: "Tripura",
      },
      {
        label: "Uttar Pradesh",
        value: "Uttar Pradesh",
      },
      {
        label: "Uttarakhand",
        value: "Uttarakhand",
      },
      {
        label: "West Bengal",
        value: "West Bengal",
      },
    ],
  },
  {
    id: "district",
    name: "district",
    label: "District",
    placeholder: "Enter district",
    required: true,
    type: "text",
  },
  {
    id: "block",
    name: "block",
    label: "Block/Urban area",
    placeholder: "Enter block",
    required: true,
    type: "text",
  },
  {
    id: "village",
    name: "village",
    label: "Village/Ward",
    placeholder: "Enter village",
    required: true,
    type: "text",
  },
  {
    id: "latitude",
    name: "latitude",
    label: "Latitude",
    placeholder: "Fetching location..",
    value: "", // Add this to allow dynamic updates
    required: true,
    type: "text",
    readOnly: true, // Prevent user edits
    disabled: true, // Add this to ensure it's not editable


  },
  {
    id: "longitude",
    name: "longitude",
    label: "Longitude",
 //   placeholder: "Fetching location..",
    required: true,
    value: "", // Add this to allow dynamic updates
    type: "text",
    readOnly: true, // Prevent user edits
    disabled: true, // Add this to ensure it's not editable


  },
  {
    id: "date",
    name: "date",
    label: "Date (dd/mm/yyyy)",
    placeholder: "Enter date",
    required: true,
    type: "date",
  },
  {
    id: "dayOfVisit",
    name: "dayOfVisit",
    label: "Day of Visit",
    placeholder: "Enter day of visit",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "National Deworming Day",
        value: "National Deworming Day",
      },
      {
        label: "Mop Up Day",
        value: "Mop Up Day",
      },
    ],
  },
  {
    id: "monitoringSiteCategory",
    name: "monitoringSiteCategory",
    label: "Monitoring Site Category",
    placeholder: "Enter monitoring site category",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "AWC",
        value: "AWC",
      },
      {
        label: "Govt. school",
        value: "Govt. school",
      },
      {
        label: "Private school",
        value: "Private school",
      },
      {
        label: "College",
        value: "College",
      },
      {
        label: "Others",
        value: "Others",
      },
    ],
  },
  {
    id: "monitoringSiteCategory-Others",
    name: "monitoringSiteCategory-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.monitoringSiteCategory === "Others",
  },
  {
    id: "siteName",
    name: "siteName",
    label: "Site Name",
    placeholder: "Enter site name",
    required: true,
    type: "text",
    showIf: (values) => values.monitoringSiteCategory === "AWC",
  },
  {
    id: "nameRespondent",
    name: "nameRespondent",
    label: "Name of Respondent",
    placeholder: "Enter name of respondent",
    required: true,
    type: "text",
    showIf: (values) => values.monitoringSiteCategory === "AWC",
  },
  {
    id: "designation",
    name: "designation",
    label: "Designation",
    placeholder: "Enter designation",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Teacher",
        value: "Teacher",
      },
      {
        label: "Principal",
        value: "Principal",
      },
      {
        label: "Others",
        value: "Others",
      },
    ],
    showIf: (values) => values.monitoringSiteCategory === "AWC",
  },
  {
    id: "designation-Others",
    name: "designation-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.designation === "Others",
  },
  {
    id: "mobileRespondent",
    name: "mobileRespondent",
    label: "Mobile Number of Respondent",
    placeholder: "Enter mobile number of respondent",
    required: true,
    type: "number",
    minLength: 10,
    maxLength: 10,
    showIf: (values) => values.monitoringSiteCategory === "AWC",
  },

  {
    id: "attendedTraining",
    name: "attendedTraining",
    label:
      "Have you or any other person from your school attended training for current round of NDD? (In current year)",
    heading: "Thematic Area I: Staff availability & Training/Capacity Building",
    placeholder: "Enter if attended training",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
  },
  {
    id: "reasonNotAttendedTraining",
    name: "reasonNotAttendedTraining",
    label: "If No, reasons for not attending training",
    placeholder: "Enter reason for not attending training",
    required: false,
    type: "radio",
    options: [
      { label: "No information", value: "no-information" },
      { label: "Location was far away", value: "location-far-away" },
      { label: "Busy in other work", value: "busy-other-work" },
      {
        label: "Not needed as attended training in previous rounds",
        value: "not-needed-previous-rounds",
      },
      { label: "Others", value: "others" },
    ],
    showIf: (values) => values.attendedTraining === "no",
  },
  {
    id: "reasonNotAttendedTraining-Others",
    name: "reasonNotAttendedTraining-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.reasonNotAttendedTraining === "Others",
  },
  {
    id: "receivedMaterialTraining",
    name: "receivedMaterialTraining",
    label: "Did you receive any NDD related material in the training?",
    placeholder: "Enter if received material training",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
  },
  {
    id: "reportingFormat",
    name: "reportingFormat",
    label: "Reporting formats",
    placeholder: "Enter reporting format",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
    showIf: (values) => values.receivedMaterialTraining === "yes",
  },
  {
    id: "guidelines",
    name: "guidelines",
    label: "Guidelines/Module",
    placeholder: "Enter guidelines",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
    showIf: (values) => values.receivedMaterialTraining === "yes",
  },
  {
    id: "receivedAlbendazole",
    name: "receivedAlbendazole",
    label: "From where did you receive Albendazole tablets?",
    placeholder: "Enter if received albendazole",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Received during training",
        value: "Received during training",
      },
      {
        label: "BRC",
        value: "BRC",
      },
      {
        label: "Others",
        value: "Others",
      },
    ],
  },
  {
    id: "receivedAlbendazole-Others",
    name: "receivedAlbendazole-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.receivedAlbendazole === "Others",
  },
  {
    id: "childrenEnrollAwc",
    name: "childrenEnrollAwc",
    label: "Total children enrolled in School",
    heading: "Thematic Area II: Target Population",
    placeholder: "Enter children enrolled in school",
    required: true,
    type: "number",
  },
  {
    id: "attendenceAwc",
    name: "attendenceAwc",
    label: "Total attendance in the school/college on the day of visit",
    placeholder: "Enter attendance",
    required: true,
    type: "number",
  },
  {
    id: "sufficientAlbendazole",
    name: "sufficientAlbendazole",
    heading: "Thematic Area III: Supply Chain & Logistics Management",
    label:
      "Are Albendazole tablets available in sufficient quantity to deworm the enrolled students?",
    placeholder: "Enter if sufficient albendazole is available",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
  },
  {
    id: "albendazoleWithinExpiry",
    name: "albendazoleWithinExpiry",
    label: "Is the available Albendazole within expiry date?",
    placeholder: "Enter if albendazole is within expiry",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
  },
  {
    id: "administeringAlbendazole",
    name: "administeringAlbendazole",
    heading: "Thematic Area IV: Drug administration & practices",
    label: "Are you administering Albendazole tablet to children today?",
    placeholder: "Enter if administering albendazole",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes, ongoing now",
        value: "Yes, ongoing now",
      },
      {
        label: "Yes, already done",
        value: "Yes, already done",
      },
      {
        label: "Yes, after lunch/mid-day meal",
        value: "Yes, after lunch/mid-day meal",
      },
      {
        label: "No, will not administer today",
        value: "No, will not administer today",
      },
    ],
  },
  {
    id: "administeringAlbendazoleOther",
    name: "administeringAlbendazoleOther",
    label: "If No, why is deworming not happening?",
    placeholder: "Enter if administering albendazole to others",
    required: false,
    type: "radio",
    options: [
      { label: "No information", value: "no-information" },
      { label: "Fear of adverse events", value: "fear-adverse-events" },
      {
        label: "Already dewormed all eligible children",
        value: "already-dewormed",
      },
      {
        label: "Unavailability of Albendazole",
        value: "unavailability-albendazole",
      },
      { label: "Others", value: "others" },
    ],
    showIf: (values) =>
      values.administeringAlbendazole === "No, will not administer today",
  },
  {
    id: "provisionDeworming",
    name: "provisionDeworming",
    label:
      "Does the school have the following provisions for the deworming process?",
    placeholder: "Enter provision for deworming",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Reporting formats",
        value: "Reporting formats",
      },
      {
        label: "Drinking water",
        value: "Drinking water",
      },
      {
        label: "ORS packets",
        value: "ORS packets",
      },
      {
        label: "Others",
        value: "Others",
      },
    ],
  },
  {
    id: "provisionDeworming-Others",
    name: "provisionDeworming-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.provisionDeworming.includes("Others"),
  },
  {
    id: "ashaPresent",
    name: "ashaPresent",
    label: "Is the ASHA present and assisting the AWW in deworming at the AWC?",
    placeholder:
      "Is the ASHA present and assisting the AWW in deworming at the AWC?",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
  },
  {
    id: "outOfSchoolChildAlbendazole",
    name: "outOfSchoolChildAlbendazole",
    label:
      "Out-of-school/unregistered children administered Albendazole tablet in the AWC?",
    placeholder: "Enter albendazole provision for out-of-school children",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
  },
  {
    id: "administeringAlbendazoleChildren",
    name: "administeringAlbendazoleChildren",
    label: "If yes, who is administering Albendazole to children?",
    placeholder: "If yes, who is administering Albendazole to children?",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "AWW",
        value: "AWW",
      },
      {
        label: "ASHA",
        value: "ASHA",
      },
    ],
    showIf: (values) => values.outOfSchoolChildAlbendazole === "yes",
  },
  {
    id: "administeringAlbendazoleChildrenAwc",
    name: "administeringAlbendazoleChildrenAwc",
    label: "Who is administering Albendazole to the children in AWC?",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Teacher",
        value: "Teacher",
      },
      {
        label: "Principal",
        value: "Principal",
      },
      {
        label: "FHW",
        value: "FHW",
      },
      {
        label: "Others",
        value: "Others",
      },
    ],
  },
  {
    id: "administeringAlbendazoleChildrenAwc-Others",
    name: "administeringAlbendazoleChildrenAwc-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.administeringAlbendazoleChildrenAwc === "Others",
  },
  {
    id: "healthEducation",
    name: "healthEducation",
    label:
      "Are AWW /ASHA /FHW giving health education related to deworming to children?",
    placeholder: "Enter if health education is provided",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
      {
        label: "Could not observe",
        value: "Could not observe",
      },
    ],
  },
  {
    id: "appropriateDoseAlbendazole",
    name: "appropriateDoseAlbendazole",
    label:
      "Whether the children are given appropriate dose of Albendazole tablet by AWW/ASHA/FHW?",
    placeholder: "Enter if appropriate dose of albendazole is administered",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
      {
        label: "Could not observe",
        value: "Could not observe",
      },
    ],
  },
  {
    id: "albendazoleEmptyStomach",
    name: "albendazoleEmptyStomach",
    label:
      "Ask AWW/ASHA/FHW, whether Albendazole tablet can be given on empty stomach?",
    placeholder: "Enter if albendazole is administered on an empty stomach",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
      {
        label: "Could not observe",
        value: "Could not observe",
      },
    ],
  },
  {
    id: "childrenChewTablet",
    name: "childrenChewTablet",
    label: "Are AWW/ASHA /FHW asking children to chew tablet in front of them?",
    placeholder: "Enter if children are instructed to chew the tablet",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
      {
        label: "Could not observe",
        value: "Could not observe",
      },
    ],
  },
  {
    id: "dewormingSickChildren",
    name: "dewormingSickChildren",
    heading: "Thematic Area V: Knowledge and attitude of Service providers",
    label:
      "According to AWW/ASHA /FHW, can deworming drug be administered to sick children?",
    placeholder: "Enter if deworming was done for sick children",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
  },
  {
    id: "possibleAdverseEvent",
    name: "possibleAdverseEvent",
    label:
      "Is the AWW /ASHA /FHW aware of the possibility of adverse events from deworming?",
    placeholder: "Enter if there is awareness of possible adverse events",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
      {
        label: "Don't know",
        value: "Don't know",
      },
    ],
  },
  {
    id: "awareAdverseEvent",
    name: "awareAdverseEvent",
    label:
      "If yes, what possible adverse events could be reported by children after having the Albendazole tablet?",
    placeholder: "Enter if there is awareness about adverse events",
    required: false,
    type: "checkbox",
    size: 12,
    options: [
      { label: "Mild abdominal pain", value: "mild-abdominal-pain" },
      { label: "Nausea/vomiting", value: "nausea-vomiting" },
      { label: "Diarrhea", value: "diarrhea" },
      { label: "Fatigue", value: "fatigue" },
      { label: "Others", value: "others" },
    ],
    showIf: (values) => values.possibleAdverseEvent === "yes",
  },
  {
    id: "awareAdverseEvent-Others",
    name: "awareAdverseEvent-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.awareAdverseEvent.includes("others"),
  },
  {
    id: "possibleAppropriateResponse",
    name: "possibleAppropriateResponse",
    label:
      "If yes, according to the AWW/ASHA /FHW, what is the appropriate response in case of severe adverse event?",
    placeholder:
      "Enter if there is knowledge of appropriate response to adverse events",
    required: false,
    type: "checkbox",
    size: 12,
    options: [
      {
        label: "Let child rest in an open and shaded place",
        value: "rest-in-shaded-place",
      },
      { label: "Provide clean water to drink", value: "provide-clean-water" },
      {
        label: "Contact the MO/nearest Health Facility",
        value: "contact-mo-health-facility",
      },
      {
        label: "Stay calm and stop deworming activities",
        value: "stay-calm-stop-deworming",
      },
      {
        label: "Pat the child's upper back to dislodge the tablet",
        value: "pat-upper-back",
      },
      {
        label: "Inform the child's parents right away",
        value: "inform-parents",
      },
      { label: "Others", value: "others" },
    ],
    showIf: (values) => values.possibleAdverseEvent === "yes",
  },
  {
    id: "possibleAppropriateResponse-Others",
    name: "possibleAppropriateResponse-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.possibleAppropriateResponse.includes("others"),
  },
  {
    id: "iecMaterial",
    name: "iecMaterial",
    label: "Which of the following IEC materials are visible at the AWC?",
    placeholder: "Which of the following IEC materials are visible at the AWC?",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Poster",
        value: "Poster",
      },
      {
        label: "Banner",
        value: "Banner",
      },
      {
        label: "Wall painting",
        value: "Wall painting",
      },
      {
        label: "Handout/leaflet",
        value: "Handout/leaflet",
      },
      {
        label: "No IEC materials",
        value: "No IEC materials",
      },
      {
        label: "Others",
        value: "Others",
      },
    ],
  },
  {
    id: "iecMaterial-Others",
    name: "iecMaterial-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.iecMaterial.includes("Others"),
  },
  {
    id: "parent1",
    name: "parent1",
    heading:
      "Which of the following community mobilization activities have been undertaken by the ASHA before NDD?",
    label: "Parent 1",
    placeholder: "Enter parent 1 details",
    type: "checkbox",
    required: true,
    options: [
      {
        label: "Conduct village meetings with parents",
        value: "conduct-village-meetings",
      },
      {
        label: "Informed parents about harmful effects of worms",
        value: "harmful-effects-worms",
      },
      {
        label: "Informed about the process of deworming",
        value: "process-deworming",
      },
      {
        label: "Informed parents about behavior change to prevent re-infection",
        value: "behavior-change-prevent-reinfection",
      },
      { label: "Others", value: "others" },
    ],
  },
  {
    id: "parent1-Others",
    name: "parent1-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.parent1.includes("others"),
  },
  {
    id: "parent2",
    name: "parent2",
    label: "Parent 2",
    placeholder: "Enter parent 2 details",
    type: "checkbox",
    required: true,
    options: [
      {
        label: "Conduct village meetings with parents",
        value: "conduct-village-meetings",
      },
      {
        label: "Informed parents about harmful effects of worms",
        value: "harmful-effects-worms",
      },
      {
        label: "Informed about the process of deworming",
        value: "process-deworming",
      },
      {
        label: "Informed parents about behavior change to prevent re-infection",
        value: "behavior-change-prevent-reinfection",
      },
      { label: "Others", value: "others" },
    ],
  },
  {
    id: "parent2-Others",
    name: "parent2-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.parent2.includes("others"),
  },
  {
    id: "parent3",
    name: "parent3",
    label: "Parent 3",
    placeholder: "Enter parent 3 details",
    type: "checkbox",
    required: true,
    options: [
      {
        label: "Conduct village meetings with parents",
        value: "conduct-village-meetings",
      },
      {
        label: "Informed parents about harmful effects of worms",
        value: "harmful-effects-worms",
      },
      {
        label: "Informed about the process of deworming",
        value: "process-deworming",
      },
      {
        label: "Informed parents about behavior change to prevent re-infection",
        value: "behavior-change-prevent-reinfection",
      },
      { label: "Others", value: "others" },
    ],
  },
  {
    id: "parent3-Others",
    name: "parent3-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.parent3.includes("others"),
  },
  {
    id: "informationNddChild1",
    name: "informationNddChild1",
    heading:
      "From where the beneficiaries got information about the ongoing round of NDD?",
    label: "Child 1",
    placeholder: "Enter NDD information for child 1",
    type: "checkbox",
    required: true,
    options: [
      { label: "Govt order/communication", value: "govt-order-communication" },
      { label: "Television", value: "television" },
      { label: "Radio", value: "radio" },
      { label: "Newspaper", value: "newspaper" },
      { label: "Social media", value: "social-media" },
      { label: "Family/friends", value: "family-friends" },
      { label: "School", value: "school" },
      { label: "FLWs (AWW/ASHA/ANM)", value: "flws-aww-asha-anm" },
      { label: "Others", value: "others" },
    ],
  },
  {
    id: "informationNddChild1-Others",
    name: "informationNddChild1-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.informationNddChild1.includes("others"),
  },
  {
    id: "informationNddChild2",
    name: "informationNddChild2",
    label: "Child 2",
    placeholder: "Enter NDD information for child 2",
    type: "checkbox",
    required: true,
    options: [
      { label: "Govt order/communication", value: "govt-order-communication" },
      { label: "Television", value: "television" },
      { label: "Radio", value: "radio" },
      { label: "Newspaper", value: "newspaper" },
      { label: "Social media", value: "social-media" },
      { label: "Family/friends", value: "family-friends" },
      { label: "School", value: "school" },
      { label: "FLWs (AWW/ASHA/ANM)", value: "flws-aww-asha-anm" },
      { label: "Others", value: "others" },
    ],
  },
  {
    id: "informationNddChild2-Others",
    name: "informationNddChild2-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.informationNddChild2.includes("others"),
  },
  {
    id: "informationNddChild3",
    name: "informationNddChild3",
    label: "Child 3",
    placeholder: "Enter NDD information for child 3",
    type: "checkbox",
    required: true,
    options: [
      { label: "Govt order/communication", value: "govt-order-communication" },
      { label: "Television", value: "television" },
      { label: "Radio", value: "radio" },
      { label: "Newspaper", value: "newspaper" },
      { label: "Social media", value: "social-media" },
      { label: "Family/friends", value: "family-friends" },
      { label: "School", value: "school" },
      { label: "FLWs (AWW/ASHA/ANM)", value: "flws-aww-asha-anm" },
      { label: "Others", value: "others" },
    ],
  },
  {
    id: "informationNddChild3-Others",
    name: "informationNddChild3-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.informationNddChild3.includes("others"),
  },
  {
    id: "wormInfectionChild1",
    name: "wormInfectionChild1",
    heading:
      "What are the different ways that children can get worm infection?",
    label: "Child 1",
    placeholder: "Enter NDD information for child 3",
    type: "checkbox",
    required: true,
    options: [
      {
        label: "Having food without washing hands",
        value: "food-without-washing-hands",
      },
      {
        label: "Not washing hands after toilet",
        value: "not-washing-hands-after-toilet",
      },
      {
        label: "Not using sanitary toilets",
        value: "not-using-sanitary-toilets",
      },
      { label: "Walking around bare foot", value: "walking-around-bare-foot" },
      {
        label: "Consuming fruits/vegetables without washing",
        value: "consuming-fruits-vegetables-without-washing",
      },
      { label: "Others", value: "others" },
    ],
  },
  {
    id: "wormInfectionChild1-Others",
    name: "wormInfectionChild1-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.wormInfectionChild1.includes("others"),
  },
  {
    id: "wormInfectionChild2",
    name: "wormInfectionChild2",
    label: "Child 2",
    placeholder: "Enter NDD information for child",
    type: "checkbox",
    required: true,
    options: [
      {
        label: "Having food without washing hands",
        value: "food-without-washing-hands",
      },
      {
        label: "Not washing hands after toilet",
        value: "not-washing-hands-after-toilet",
      },
      {
        label: "Not using sanitary toilets",
        value: "not-using-sanitary-toilets",
      },
      { label: "Walking around bare foot", value: "walking-around-bare-foot" },
      {
        label: "Consuming fruits/vegetables without washing",
        value: "consuming-fruits-vegetables-without-washing",
      },
      { label: "Others", value: "others" },
    ],
  },
  {
    id: "wormInfectionChild2-Others",
    name: "wormInfectionChild2-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.wormInfectionChild2.includes("others"),
  },
  {
    id: "wormInfectionChild3",
    name: "wormInfectionChild3",
    label: "Child 3",
    placeholder: "Enter NDD information for child",
    type: "checkbox",
    required: true,
    options: [
      {
        label: "Having food without washing hands",
        value: "food-without-washing-hands",
      },
      {
        label: "Not washing hands after toilet",
        value: "not-washing-hands-after-toilet",
      },
      {
        label: "Not using sanitary toilets",
        value: "not-using-sanitary-toilets",
      },
      { label: "Walking around bare foot", value: "walking-around-bare-foot" },
      {
        label: "Consuming fruits/vegetables without washing",
        value: "consuming-fruits-vegetables-without-washing",
      },
      { label: "Others", value: "others" },
    ],
  },
  {
    id: "wormInfectionChild3-Others",
    name: "wormInfectionChild3-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.wormInfectionChild3.includes("others"),
  },
  {
    id: "supervisorVisited",
    name: "supervisorVisited",
    label: "Any supervisor visited this site on the day of NDD?",
    placeholder: "Enter if supervisor visited during deworming",
    required: true,
    type: "radio",
    size: 12,
    options: [
      {
        label: "Yes",
        value: "yes",
      },
      {
        label: "No",
        value: "no",
      },
    ],
  },
];

export const postNDDFields = [
  {
    id: "reportingDate",
    name: "reportingDate",
    label: "Basic Information",
    heading: "Date of Reporting",
    placeholder: "Enter the reporting date",
    required: true,
    type: "date",
  },
  {
    id: "state",
    name: "state",
    label: "State",
    placeholder: "Enter state",
    required: true,
    type: "radio",
    options: [
      {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands",
      },
      {
        label: "Andhra Pradesh",
        value: "Andhra Pradesh",
      },
      {
        label: "Arunachal Pradesh",
        value: "Arunachal Pradesh",
      },
      {
        label: "Assam",
        value: "Assam",
      },
      {
        label: "Bihar",
        value: "Bihar",
      },
      {
        label: "Chhattisgarh",
        value: "Chhattisgarh",
      },
      {
        label: "Chandigarh",
        value: "Chandigarh",
      },
      {
        label: "Delhi",
        value: "Delhi",
      },
      {
        label: "Dadra & Nagar Haveli and Daman & Diu",
        value: "Dadra & Nagar Haveli and Daman & Diu",
      },
      {
        label: "Goa",
        value: "Goa",
      },
      {
        label: "Gujarat",
        value: "Gujarat",
      },
      {
        label: "Haryana",
        value: "Haryana",
      },
      {
        label: "Himachal Pradesh",
        value: "Himachal Pradesh",
      },
      {
        label: "Jharkhand",
        value: "Jharkhand",
      },
      {
        label: "Jammu & Kashmir",
        value: "Jammu & Kashmir",
      },
      {
        label: "Kerala",
        value: "Kerala",
      },
      {
        label: "Karnataka",
        value: "Karnataka",
      },
      {
        label: "Ladakh",
        value: "Ladakh",
      },
      {
        label: "Lakshadweep",
        value: "Lakshadweep",
      },
      {
        label: "Manipur",
        value: "Manipur",
      },
      {
        label: "Maharashtra",
        value: "Maharashtra",
      },
      {
        label: "Madhya Pradesh",
        value: "Madhya Pradesh",
      },
      {
        label: "Mizoram",
        value: "Mizoram",
      },
      {
        label: "Meghalaya",
        value: "Meghalaya",
      },
      {
        label: "Nagaland",
        value: "Nagaland",
      },
      {
        label: "Odisha",
        value: "Odisha",
      },
      {
        label: "Punjab",
        value: "Punjab",
      },
      {
        label: "Puducherry",
        value: "Puducherry",
      },
      {
        label: "Rajasthan",
        value: "Rajasthan",
      },
      {
        label: "Sikkim",
        value: "Sikkim",
      },
      {
        label: "Tamil Nadu",
        value: "Tamil Nadu",
      },
      {
        label: "Telangana",
        value: "Telangana",
      },
      {
        label: "Tripura",
        value: "Tripura",
      },
      {
        label: "Uttar Pradesh",
        value: "Uttar Pradesh",
      },
      {
        label: "Uttarakhand",
        value: "Uttarakhand",
      },
      {
        label: "West Bengal",
        value: "West Bengal",
      },
    ],
  },
  {
    id: "nameOfficial",
    name: "nameOfficial",
    label: "Name of official filling the information",
    placeholder: "Enter the name of the official",
    required: true,
    type: "text",
  },
  {
    id: "designationOfficial",
    name: "designationOfficial",
    label: "Designation of official filling the information",
    placeholder: "Enter the designation of the official",
    required: true,
    type: "text",
  },
  {
    id: "mobileOfficial",
    name: "mobileOfficial",
    label: "Mobile no. of official filling the information",
    placeholder: "Enter the mobile number of the official",
    required: true,
    type: "number",
    minLength: 10,
    maxLength: 10,
  },
  {
    id: "noOfDistrict",
    name: "noOfDistrict",
    label: "1a. Total number of Districts in the State",
    heading: "Program Head and Indicator A: Program planning and management",
    placeholder: "Enter the number of districts",
    required: true,
    type: "number",
  },
  {
    id: "stateImplementedNdd",
    name: "stateImplementedNdd",
    label:
      "1b. Total number of districts which implemented NDD 2024 February round",
    placeholder: "Select if the state implemented NDD",
    required: true,
    type: "number",
  },
  {
    id: "noOfBlock",
    name: "noOfBlock",
    label: "2a. Total number of blocks in the State",
    placeholder: "Enter the number of blocks",
    type: "number",
    required: true,
  },
  {
    id: "noOfBlockNdd",
    name: "noOfBlockNdd",
    label:
      "2b. Total number of blocks which implemented NDD 2024 February round",
    placeholder: "Enter the number of blocks implementing NDD",
    type: "number",
    required: true,
  },
  {
    id: "stateImplementedNdd",
    name: "stateImplementedNdd",
    label: "3a. Did the state implemented NDD 2024 February round?",
    placeholder: "Has the state implemented NDD?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "dateImplementedNdd",
    name: "dateImplementedNdd",
    label: "3b. If yes, date on which NDD 2024 February round was implemented",
    placeholder: "Specify the date",
    type: "date",
    required: false,
    showIf: (values) => values.stateImplementedNdd === "yes",
  },
  {
    id: "reasonNotImplementedNdd",
    name: "reasonNotImplementedNdd",
    label:
      "3b. If no, reasons for non-implementation of the NDD round on 10th February 2024",
    placeholder: "Specify the reason",
    type: "text",
    required: false,
    showIf: (values) => values.stateImplementedNdd === "no",
  },
  {
    id: "stateImplementedMopup",
    name: "stateImplementedMopup",
    label:
      "4a. Did the state implemented Mop-up Day for NDD 2024 February round?",
    placeholder: "Has the state implemented mop-up?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "dateImplementedNdd",
    name: "dateImplementedNdd",
    label:
      "4b. If yes, date on which Mop-up Day for NDD 2024 February round was implemented",
    placeholder: "Specify the date",
    type: "date",
    required: false,
    showIf: (values) => values.stateImplementedMopup === "yes",
  },
  {
    id: "reasonNotImplementedMopup",
    name: "reasonNotImplementedMopup",
    label: "4b. If no, reasons for non-implementation of Mop-up day",
    placeholder: "Specify the reason",
    type: "text",
    required: false,
    showIf: (values) => values.stateImplementedMopup === "no",
  },
  {
    id: "noOfDistrictLfMda",
    name: "noOfDistrictLfMda",
    label: "5a. Total number of districts where LF MDA was scheduled/planned",
    placeholder: "Enter the number of districts for LF MDA",
    type: "number",
    required: true,
  },
  {
    id: "noOfDistrictLfMdaCompleted",
    name: "noOfDistrictLfMdaCompleted",
    label: "5b. Name of LF MDA districts, which completed MDA in February 2024",
    placeholder: "Enter the number of districts where LF MDA is completed",
    type: "number",
    required: true,
  },
  {
    id: "noOfDistrictLfMdaNotCompleted",
    name: "noOfDistrictLfMdaNotCompleted",
    label:
      "5c. Name of LF MDA districts which could not implement MDA in August 2023",
    placeholder: "Enter the number of districts where LF MDA is not completed",
    type: "number",
    required: true,
  },
  {
    id: "operationPlanned",
    name: "operationPlanned",
    label:
      "6. Did the state develop any operational plan (with activities and timelines) for NDD 2024 February round?",
    placeholder: "Is the operation planned?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "stateSccConducted",
    name: "stateSccConducted",
    label:
      "7. Did the state conduct State Coordination Committee Meeting (s) for NDD 2024 February round as per operational guidelines?",
    placeholder: "Has the State SCC been conducted?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "stateSccConductedChaired",
    name: "stateSccConductedChaired",
    label: "7a. If Yes, who chaired the meeting?",
    placeholder: "Mention designation of the official",
    type: "text",
    showIf: (values) => values.stateSccConducted === "yes",
  },
  {
    id: "lfNodalParticipated",
    name: "lfNodalParticipated",
    label:
      "7b. If Yes, did the LF Nodal (only for Lymphatic Filariasis endemic states) participated in such Meetings?",
    placeholder: "Has the LF Nodal participated?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
      { label: "Non LF state", value: "non-lf-state" },
    ],
    showIf: (values) => values.stateSccConducted === "yes",
  },
  {
    id: "nddSccMeeting",
    name: "nddSccMeeting",
    label:
      "8. Did NDD implementing districts in State conduct District Coordination Committee Meetings (DCCM) prior to NDD as per operational guidelines?",
    placeholder: "Has the NDD SCC meeting been conducted?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "stateJointDirectives",
    name: "stateJointDirectives",
    label:
      "9. Did the State issue joint directives signed by Health, Education & ICDS Secretaries to districts and blocks for NDD implementation?",
    placeholder: "Have state joint directives been issued?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "developmentPartnersSupported",
    name: "developmentPartnersSupported",
    label:
      "10. Development partners supported/provided technical assistance for NDD 2024 February round implementation- if any",
    placeholder: "Have development partners provided support?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },

  {
    id: "namePartner",
    name: "namePartner",
    label: "10a. If Yes, please mention the name (s) of that partner",
    placeholder: "Enter the partner's name",
    type: "text",
    showIf: (values) => values.developmentPartnersSupported === "yes",
  },
  {
    id: "statesufficientBudget",
    name: "statesufficientBudget",
    label:
      "11. Did the state have sufficient budget available (in PIP) for NDD 2024 February round implementation?",
    placeholder: "Sufficient budget availability",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "stateBudgetReason",
    name: "stateBudgetReason",
    label: "11a. If No, what are the reasons. Please elaborate.",
    placeholder: "Enter the reason",
    type: "text",
    showIf: (values) => values.statesufficientBudget === "no",
  },
  {
    id: "stateCoverSchools",
    name: "stateCoverSchools",
    label:
      "12. Did the State cover private schools/ Madrasa schools in all NDD implementing districts?",
    placeholder: "Coverage of schools",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "programTargetPopulation",
    name: "programTargetPopulation",
    label:
      "13. Was there any revision to the program target population (denominator) done while submitting NDD 2024 February round coverage report?",
    placeholder: "Specify the target population",
    type: "text",
    required: true,
  },
  {
    id: "issueChallenges",
    name: "issueChallenges",
    label:
      "14. Share specific issues and challenges, if any, the State faced during NDD 2024 February round",
    placeholder: "Describe any challenges",
    type: "text",
    required: true,
  },
  {
    id: "innovativemeasures",
    name: "innovativemeasures",
    label:
      "15. Whether the State undertaken any innovative measures during NDD 2024 February round to increase program coverage. Please share details, if so",
    placeholder: "Mention innovative measures",
    type: "text",
    required: true,
  },
  {
    id: "stateIssueDirectives",
    name: "stateIssueDirectives",
    label:
      "16. Did the State issued directives/letter to leverage the strengthening of NDD program delivery in aspirational districts?",
    placeholder: "State directive status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
      { label: "NA", value: "na" },
    ],
    required: true,
  },
  {
    id: "albendazoleSyrup",
    name: "albendazoleSyrup",
    heading: "Program Head and Indicator B: Drug procurement and supply",
    label: "1. Did the State used 'Albendazole Syrup' for Under-5 children?",
    placeholder: "Availability of syrup",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "albendazoleTabletsOrdered",
    name: "albendazoleTabletsOrdered",
    label:
      "2. Albendazole tablets ordered by State/Districts for NDD February 2023 round",
    placeholder: "Albendazole tablets ordered status",
    type: "number",
    required: true,
  },
  {
    id: "drugQuality",
    name: "drugQuality",
    label:
      "3. Did the State/district conduct independent drug quality testing before distribution?",
    placeholder: "Drug quality checked status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "albendazoleLeftover",
    name: "albendazoleLeftover",
    label:
      "4. Left-over Albendazole tablets at state and districts (to be utilized in NDD February 2024 round",
    placeholder:
      "Pls count the number of those left over tablets, which are in usable condition including loose tablets (refer page no 11 of drug management guidelines for NDD program) This includes tablets procured by states as well donated tablets",
    type: "text",
    required: true,
  },
  {
    id: "trainingMaterial",
    name: "trainingMaterial",
    heading: "Program Head and Indicator C: Training and distribution",
    label:
      "1. Which of the following training material that was used in your state for NDD 2024 February round implementation?",
    placeholder: "Training material distribution status",
    type: "checkbox",
    options: [
      { label: "Flipcharts and PPT", value: "flipcharts_ppt" },
      {
        label: "Handout for teachers/headmasters",
        value: "handout_teachers_headmasters",
      },
      {
        label: "Handout for anganwadi workers",
        value: "handout_anganwadi_workers",
      },
      { label: "Handout for ASHAs", value: "handout_ashas" },
      { label: "Others", value: "others" },
    ],
    required: true,
  },
  {
    id: "trainingMaterial-Others",
    name: "trainingMaterial-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.trainingMaterial.includes("others"),
  },
  {
    id: "communityMobilizationMaterials",
    name: "communityMobilizationMaterials",
    heading: "Program Head and Indicator D: IEC and community mobilization",
    label:
      "1. Which of following IEC and community mobilization material was used in your State for NDD 2024 February round implementation?",
    placeholder: "Community mobilization materials status",
    type: "checkbox",
    options: [
      {
        label: "Posters for Schools and Anganwadis",
        value: "posters_schools_anganwadis",
      },
      { label: "Banner", value: "banner" },
      { label: "Radio Jingle", value: "radio_jingle" },
      { label: "TV Advertisement", value: "tv_advertisement" },
      { label: "Community Handbill", value: "community_handbill" },
      { label: "Others", value: "others" },
    ],
    required: true,
  },
  {
    id: "communityMobilizationMaterials-Others",
    name: "communityMobilizationMaterials-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) =>
      values.communityMobilizationMaterials.includes("others"),
  },
  {
    id: "smsPlan",
    name: "smsPlan",
    label:
      "2. Did the State use SMS plan to reinforce training schedule and key training messages?",
    placeholder: "SMS plan status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "smsSent",
    name: "smsSent",
    label: "2a. If Yes, to which functionaries the SMSs were sent?",
    placeholder: "SMS sent status",
    type: "checkbox",
    options: [
      { label: "ANM", value: "anm" },
      { label: "AWW", value: "aww" },
      { label: "Teachers", value: "teachers" },
      { label: "CMO", value: "cmo" },
      { label: "MO", value: "mo" },
      { label: "Others", value: "others" },
    ],
    showIf: (values) => values.smsPlan === "yes",
  },
  {
    id: "smsSent-Others",
    name: "smsSent-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.smsSent.includes("others"),
  },
  {
    id: "stateEngageAsha",
    name: "stateEngageAsha",
    label:
      "3. Did the State engage ASHAs for community mobilisation, spreading awareness on NDD and mobilising out of school and unregistered children?",
    placeholder: "Engagement of ASHA workers",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "statePressSensitization",
    name: "statePressSensitization",
    label: "4. Has State conducted press sensitization meeting?",
    placeholder: "Press sensitization conducted status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "launchNdd",
    name: "launchNdd",
    label: "5. Was there a launch of NDD at State level?",
    placeholder: "NDD program launch status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "socialMedia",
    name: "socialMedia",
    label:
      "6. Has your State engaged in any form of social media to increase awareness about NDD?",
    placeholder: "Social media promotion status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "mentionSocialMedia",
    name: "mentionSocialMedia",
    label: "6a. If Yes, mention the media",
    placeholder: "Provide details",
    type: "checkbox",
    options: [
      { label: "Facebook", value: "facebook" },
      { label: "Twitter (X)", value: "twitter_x" },
      { label: "Instagram", value: "instagram" },
      { label: "Others", value: "others" },
    ],
    showIf: (values) => values.socialMedia === "yes",
  },
  {
    id: "mentionSocialMedia-Others",
    name: "mentionSocialMedia-Others",
    label: "If others, please specify",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.socialMedia.includes("others"),
  },
  {
    id: "videoConferencing",
    name: "videoConferencing",
    heading: "Program Head and Indicator E: Program monitoring",
    label:
      "1. Did the State conduct any video conferencing (VC) call/meeting with districts/blocks to review the preparations for NDD?",
    placeholder: "Video conferencing conducted status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "stateDeputeMonitors",
    name: "stateDeputeMonitors",
    label:
      "2. Did the State depute State and district level officials as NDD Monitors for field monitoring of NDD and MUD?",
    placeholder: "Monitors deputed status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "stateHolderDept",
    name: "stateHolderDept",
    label:
      "3. Whether stakeholder departments – Education, WCD and any others were part of the monitoring.",
    placeholder: "Stakeholders engagement status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "stateholderDeptDetails",
    name: "stateholderDeptDetails",
    label: "3a. If yes, please share details",
    placeholder: "3a. If yes, please share details",
    type: "text",
    showIf: (values) => values.stateHolderDept === "yes",
  },
  {
    id: "stateconductMonitoring",
    name: "stateconductMonitoring",
    label:
      "4. Did the State conduct monitoring of district and block level trainings for NDD?",
    placeholder: "Monitoring conducted status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "stateFollowUpIndependentMonitoring",
    name: "stateFollowUpIndependentMonitoring",
    label:
      "5. Did the State conduct any independent monitoring/coverage validation of NDD round?",
    placeholder: "Follow-up monitoring status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
];

export const preNDDFields = [
  {
    id: "reportingDate",
    name: "reportingDate",
    heading: "Basic Information",
    label: "Date of Reporting (dd/mm/yyyy)",
    placeholder: "Select the reporting date",
    type: "date",
    required: true,
  },
  {
    id: "state",
    name: "state",
    label: "State",
    placeholder: "Enter state",
    required: true,
    type: "radio",
    options: [
      {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands",
      },
      {
        label: "Andhra Pradesh",
        value: "Andhra Pradesh",
      },
      {
        label: "Arunachal Pradesh",
        value: "Arunachal Pradesh",
      },
      {
        label: "Assam",
        value: "Assam",
      },
      {
        label: "Bihar",
        value: "Bihar",
      },
      {
        label: "Chhattisgarh",
        value: "Chhattisgarh",
      },
      {
        label: "Chandigarh",
        value: "Chandigarh",
      },
      {
        label: "Delhi",
        value: "Delhi",
      },
      {
        label: "Dadra & Nagar Haveli and Daman & Diu",
        value: "Dadra & Nagar Haveli and Daman & Diu",
      },
      {
        label: "Goa",
        value: "Goa",
      },
      {
        label: "Gujarat",
        value: "Gujarat",
      },
      {
        label: "Haryana",
        value: "Haryana",
      },
      {
        label: "Himachal Pradesh",
        value: "Himachal Pradesh",
      },
      {
        label: "Jharkhand",
        value: "Jharkhand",
      },
      {
        label: "Jammu & Kashmir",
        value: "Jammu & Kashmir",
      },
      {
        label: "Kerala",
        value: "Kerala",
      },
      {
        label: "Karnataka",
        value: "Karnataka",
      },
      {
        label: "Ladakh",
        value: "Ladakh",
      },
      {
        label: "Lakshadweep",
        value: "Lakshadweep",
      },
      {
        label: "Manipur",
        value: "Manipur",
      },
      {
        label: "Maharashtra",
        value: "Maharashtra",
      },
      {
        label: "Madhya Pradesh",
        value: "Madhya Pradesh",
      },
      {
        label: "Mizoram",
        value: "Mizoram",
      },
      {
        label: "Meghalaya",
        value: "Meghalaya",
      },
      {
        label: "Nagaland",
        value: "Nagaland",
      },
      {
        label: "Odisha",
        value: "Odisha",
      },
      {
        label: "Punjab",
        value: "Punjab",
      },
      {
        label: "Puducherry",
        value: "Puducherry",
      },
      {
        label: "Rajasthan",
        value: "Rajasthan",
      },
      {
        label: "Sikkim",
        value: "Sikkim",
      },
      {
        label: "Tamil Nadu",
        value: "Tamil Nadu",
      },
      {
        label: "Telangana",
        value: "Telangana",
      },
      {
        label: "Tripura",
        value: "Tripura",
      },
      {
        label: "Uttar Pradesh",
        value: "Uttar Pradesh",
      },
      {
        label: "Uttarakhand",
        value: "Uttarakhand",
      },
      {
        label: "West Bengal",
        value: "West Bengal",
      },
    ],
  },
  {
    id: "nameOfficial",
    name: "nameOfficial",
    label: "Name of official filling the information",
    placeholder: "Enter the official's name",
    type: "text",
    required: true,
  },
  {
    id: "designationOfficial",
    name: "designationOfficial",
    label: "Designation of official filling the information",
    placeholder: "Enter the designation",
    type: "text",
    required: true,
  },
  {
    id: "mobileOfficial",
    name: "mobileOfficial",
    label: "Mobile no. of official filling the information",
    placeholder: "Enter the mobile number",
    type: "number",
    required: true,
    minLength: 10,
    maxLength: 10,
  },
  {
    id: "noOfDistrict",
    name: "noOfDistrict",
    heading: "Key Indicators",
    label: "1. Total number of Districts in your state",
    placeholder: "Enter the total number of districts",
    type: "number",
    required: true,
  },
  {
    id: "noOfDistrictImplementNdd",
    name: "noOfDistrictImplementNdd",
    label:
      "2. Total number of Districts which will be implementing NDD 2024 February round",
    placeholder: "Enter the number of districts implementing NDD",
    type: "number",
    required: true,
  },
  {
    id: "noOfDistrictLfNdaNdd",
    name: "noOfDistrictLfNdaNdd",
    label:
      "3. Total number of districts where LF MDA will be implemented along with NDD 2024 February round",
    placeholder: "Enter the number of LF/NDA districts",
    type: "number",
    required: true,
  },
  {
    id: "dateNddFirstRound",
    name: "dateNddFirstRound",
    label: "4. Date of NDD for NDD 2024 February round (First round)",
    placeholder: "Select the date for the first round of NDD",
    type: "date",
    required: true,
  },
  {
    id: "dateMop",
    name: "dateMop",
    label: "5. Date for Mop Up Day (MUD) for NDD 2024 February round",
    placeholder: "Select the date for the mop-up round",
    type: "date",
    required: true,
  },
  {
    id: "totalTargetedChildren",
    name: "totalTargetedChildren",
    label:
      "6.b Total number of children targeted (1-19 years) to be dewormed under NDD 2024 February round- Category-wise (a to e)",
    placeholder: "Enter the total number of targeted children",
    type: "text",
    required: true,
  },
  {
    id: "targetedChildrenRegisteredAwc",
    name: "targetedChildrenRegisteredAwc",
    label: "6a. Targeted children registered in AWCs",
    placeholder: "Enter the number of children registered in AWC",
    type: "number",
    required: true,
  },
  {
    id: "targetedChildrenUnregisteredAwc",
    name: "targetedChildrenUnregisteredAwc",
    label: "6b. Targeted children unregistered/out of AWCs",
    placeholder: "Enter the number of unregistered children in AWC",
    type: "number",
    required: true,
  },
  {
    id: "targetedChildrenEnrollGovt",
    name: "targetedChildrenEnrollGovt",
    label: "6c. Targeted children enrolled in Govt/Govt aided schools",
    placeholder: "Enter the number of children enrolled in government schools",
    type: "number",
    required: true,
  },
  {
    id: "targetedChildrenEnrollPvt",
    name: "targetedChildrenEnrollPvt",
    label: "6d. Targeted children enrolled in private schools",
    placeholder: "Enter the number of children enrolled in private schools",
    type: "number",
    required: true,
  },
  {
    id: "targetedChildrenOutOfSchool",
    name: "targetedChildrenOutOfSchool",
    label: "6e. Targeted children out of schools",
    placeholder: "Enter the number of out-of-school children",
    type: "number",
    required: true,
  },
  {
    id: "IstargetedChildrenDewormInlineWithCensus",
    name: "IstargetedChildrenDewormInlineWithCensus",
    label:
      "7. Is the targeted number of children to be dewormed under NDD 2024 February round in line with census population estimates?",
    placeholder: "Census alignment status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "noOfDistrictNdd",
    name: "noOfDistrictNdd",
    label: "8. Number of districts for the NDD 2024 February round",
    placeholder: "Enter the number of districts conducting NDD",
    type: "number",
    required: false,
  },
  {
    id: "leftoverAlbendazoleStateDist",
    name: "leftoverAlbendazoleStateDist",
    label:
      "9a. Left-over Albendazole tablets at state and districts (to be utilized in NDD 2024 February round)",
    placeholder: "Enter the leftover quantity",
    type: "number",
  },
  {
    id: "drugProcurementQuantity",
    name: "drugProcurementQuantity",
    label:
      "9b. Drug procurement quantity ordered for NDD 2024 February round (Number of tablets)",
    placeholder: "Enter the procured quantity",
    type: "number",
    required: true,
  },
  {
    id: "albendazoleSupplied",
    name: "albendazoleSupplied",
    label:
      "9c. Albendazole tablets supplied by manufacturer/s as on the date of reporting (Number of tablets)",
    placeholder: "Enter the supplied quantity",
    type: "number",
    required: true,
  },
  {
    id: "completedDrugQualityTesting",
    name: "completedDrugQualityTesting",
    label:
      "9d. Has the State completed independent drug quality testing of Albendazole, supplied by manufacturer/s so far? yes/No",
    placeholder: "Drug quality testing status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
      { label: "NA", value: "NA" },
    ],
    required: true,
  },
  {
    id: "conductedStateCoordinationMeeting",
    name: "conductedStateCoordinationMeeting",
    label:
      "10a. Has the State conducted NDD State Coordination Committee meeting with participation from Education, WCD, Swachh Bharat Mission, Private School, LF Association and other concerned departments? Yes/No",
    placeholder: "State coordination meeting status",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
      { label: "Not planned", value: "Not planned" },
    ],
    required: true,
  },
  {
    id: "meetingDate",
    name: "meetingDate",
    label:
      "10b. If Yes , write the meeting date. If No, write the planned tentative date for the meeting",
    placeholder: "Select the meeting date",
    type: "date",
    showIf: (values) => values.conductedStateCoordinationMeeting === "yes",
  },
  {
    id: "participationConcernedDept",
    name: "participationConcernedDept",
    label:
      "10c. If yes, write the participation from concerned department(s) in the meeting",
    placeholder: "Participation status",
    type: "checkbox",
    options: [
      { label: "Education", value: "education" },
      { label: "LF Association", value: "lf-association" },
      { label: "WCD", value: "wcd" },
      { label: "Swachh Bharat Mission", value: "swachh-bharat-mission" },
      { label: "Private Schools", value: "private-schools" },
      { label: "Others", value: "others" },
    ],
    required: true,
    showIf: (values) => values.conductedStateCoordinationMeeting === "yes",
  },
  {
    id: "participationConcernedDept-Others",
    name: "participationConcernedDept-Others",
    label: "10d. If Others(specify)",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) =>
      values.conductedStateCoordinationMeeting.includes("others"),
  },
  {
    id: "plannedMeetingDate",
    name: "plannedMeetingDate",
    label: "10e. If No, write the planned tentative date for the meeting",
    placeholder: "Select the planned meeting date",
    type: "date",
    required: true,
  },
  {
    id: "stateLevelLaunchPlanned",
    name: "stateLevelLaunchPlanned",
    label: "10f. Has a state-level launch been planned?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "isPrintingIecCompleted",
    name: "isPrintingIecCompleted",
    label:
      "11. Is printing of IEC (poster, banners, hoardings etc.) completed? (Yes/No/In-process",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
      { label: "In-process", value: "in-process" },
    ],
    required: true,
  },
  {
    id: "statedPlannedIecCampaign",
    name: "statedPlannedIecCampaign",
    label:
      "12. Has State planned for IEC campaign at state level including state launch, press sensitisation meeting ? Yes/No",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "statePraparationMassmedia",
    name: "statePraparationMassmedia",
    label:
      "13. Has State completed preparations for mass media activities such as radio jingle, TV spot/scroll, miking, newspaper advertisement etc?",
    placeholder: "Enter details of mass media campaign preparation",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "socialMediaAwareness",
    name: "socialMediaAwareness",
    label:
      "14. Has state engaged in any form of social media to increase awareness about NDD?",
    type: "checkbox",
    options: [
      { label: "Facebook", value: "facebook" },
      { label: "Twitter (X)", value: "twitter_x" },
      { label: "Instagram", value: "instagram" },
      { label: "Others", value: "others" },
    ],
    required: true,
  },
  {
    id: "socialMediaAwareness-Others",
    name: "socialMediaAwareness-Others",
    label: "14a. If others (specify)",
    placeholder: "If others, please specify",
    type: "text",
    showIf: (values) => values.socialMediaAwareness.includes("others"),
  },
  {
    id: "stateLevelTrainingCompleted",
    name: "stateLevelTrainingCompleted",
    label: "15a. Is state level training completed? Yes/No",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "distLevelTrainingCompleted",
    name: "distLevelTrainingCompleted",
    label:
      "15b. Is District level training is completed? Yes/No (if yes, no. of districts)",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "noOfDistLevelTrainingCompleted",
    name: "noOfDistLevelTrainingCompleted",
    label: "15bi. (if yes, no. of districts)",
    placeholder: "Enter the number of district-level trainings completed",
    type: "number",
    showIf: (values) => values.distLevelTrainingCompleted === "yes",
    required: true,
  },
  {
    id: "blockLevelTrainingCompleted",
    name: "blockLevelTrainingCompleted",
    label: "15c. is Training at block/cluster/sector level completed? Yes/No",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "albendazoleIecMaterialDistributed",
    name: "albendazoleIecMaterialDistributed",
    label:
      "16. Is albendazole supplies, IEC and training material being distributed to schools and anganwadi during block/cluster/sector level trainings? Yes/No",
    placeholder: "Enter details of materials distributed",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "iecMaterialToSchool",
    name: "iecMaterialToSchool",
    label:
      "16a. Are IEC materials being distributed to schools and anganwadi during block/cluster/sector level orientation?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
  },
  {
    id: "allDistrictConductedNDDcommittee",
    name: "allDistrictConductedNDDcommittee",
    label:
      "17. Have all districts conducted NDD district coordination committee meetings (DCCMs)? Yes/No",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "stateConductedVideoConferencing",
    name: "stateConductedVideoConferencing",
    label:
      "18a. Has the state conducted video-conferencing (VC) on NDD with all districts to assess the preparations? Yes/No",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "stateConductedVideoConferencing_date",
    name: "stateConductedVideoConferencing_date",
    label: "18b. If Yes to above then write date of VC",
    placeholder: "Select the video conferencing date",
    type: "date",
    showIf: (values) => values.stateConductedVideoConferencing === "yes",
  },
  {
    id: "guidelinesForDist",
    name: "guidelinesForDist",
    label:
      "19. Have guidelines been issued to districts and blocks for managing any adverse events? Yes/No",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
  {
    id: "stateDevelopedFieldMonitoringPlan",
    name: "stateDevelopedFieldMonitoringPlan",
    label:
      "20. Has state developed the field monitoring plan for deworming day and Mop Up Day? Yes/No",
    type: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    required: true,
  },
];
