import React, { Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import App from "./App";
import {
  AMBRanking,
  TestsAndTreatment,
  About,
  AdminPanel,
  Beneficiaries,
  ChecklistBeneficiary,
  ChecklistDistrict,
  ContactUs,
  CountAdmin,
  DeleteExcelUploads,
  DrugRequirements,
  ErrorPage,
  ForgotPassword,
  IFAStock,
  KPI,
  Login,
  Register,
  ResourceAdmin,
  ResourcePage,
  SchoolForm,
  StrategyPage,
  UploadDenominators,
  UploadNumerators,
} from "./pages";

import Checklist from "pages/checklist/ChecklistState";
import BeneficiaryService from "pages/connect/BeneficiaryService";
import DenominatorsForgotPassword from "pages/reports/denominators/DenominatorsForgotPassword";
import DenominatorsHome from "pages/reports/denominators/DenominatorsHome";
import DenominatorsLogin from "pages/reports/denominators/DenominatorsLogin";
import DenominatorsRegister from "pages/reports/denominators/DenominatorsRegister";
import IndiaSituation from "pages/situation/IndiaSituation";
import StateSituation from "pages/situation/StateSituation";
import WorldSituation from "pages/situation/WorldSituation";

import DenominatorsLayout from "containers/DenominatorsLayout";
import MonitoringNDD from "pages/ndd-checklist/MonitoringNDD";
import PostNDD from "pages/ndd-checklist/PostNDD";
import PreNDD from "pages/ndd-checklist/PreNDD";
import { AdminLayout, HomeLayout } from "./containers/Layout";
import AuthProvider, { useAuth } from "./context/AuthContext";
import CustomLoader from "components/shared/CustomLoader";

const parseJwt = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const rawBytes = new TextDecoder().decode(
      Uint8Array.from(atob(base64), (c) => c.charCodeAt(0))
    );
    return JSON.parse(rawBytes);
  } catch (e) {
    return null;
  }
};

function AppRouter() {
  const { token, removeToken, stateToken } = useAuth();

  useEffect(() => {
    const handleLogout = () => {
      removeToken();
    };

    if (token) {
      const decodedJwt = parseJwt(token);

      if (decodedJwt?.exp * 1000 < Date.now()) {
        handleLogout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const ProtectedRoute = ({ children }) => {
    if (!token) {
      return <Navigate to="/auth/login" replace={true} />;
    }

    return children;
  };

  const StateUserRoute = ({ children }) => {
    if (!stateToken && stateToken !== "") {
      return <Navigate to="/reports/denominators/login" replace={true} />;
    }

    return children;
  };

  const routes = [
    {
      path: "/",
      element: (
        <Suspense fallback={<CustomLoader />}>
          <HomeLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<CustomLoader />}>
              <App />
            </Suspense>
          ),
        },
        {
          path: "checklist/state",
          element: (
            <Suspense fallback={<CustomLoader />}>
              <Checklist />
            </Suspense>
          ),
        },
        {
          path: "programme",
          children: [
            {
              path: "about",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <About />
                </Suspense>
              ),
            },
            {
              path: "6x6x6-strategy",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <StrategyPage />
                </Suspense>
              ),
            },
            {
              path: "beneficiaries",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <Beneficiaries />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "anemia-situation",
          children: [
            {
              path: "global",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <WorldSituation />
                </Suspense>
              ),
            },
            {
              path: "india",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <IndiaSituation />
                </Suspense>
              ),
            },
            {
              path: "state",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <StateSituation />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "reports",
          children: [
            {
              path: "key-performance-indicators",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <KPI />
                </Suspense>
              ),
            },
            {
              path: "ranking",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <AMBRanking />
                </Suspense>
              ),
            },
            {
              path: "testsTreatment",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <TestsAndTreatment />
                </Suspense>
              ),
            },
            {
              path: "stock",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <IFAStock />
                </Suspense>
              ),
            },
            {
              path: "denominators",
              children: [
                {
                  path: "login",
                  element: (
                    <Suspense fallback={<CustomLoader />}>
                      <DenominatorsLogin />
                    </Suspense>
                  ),
                },
                {
                  path: "get-credentials",
                  element: (
                    <Suspense fallback={<CustomLoader />}>
                      <DenominatorsRegister />
                    </Suspense>
                  ),
                },
                {
                  path: "forgot-password",
                  element: (
                    <Suspense fallback={<CustomLoader />}>
                      <DenominatorsForgotPassword />
                    </Suspense>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "resources/home",
          element: (
            <Suspense fallback={<CustomLoader />}>
              <ResourcePage />
            </Suspense>
          ),
        },
        {
          path: "contact/home",
          element: (
            <Suspense fallback={<CustomLoader />}>
              <ContactUs />
            </Suspense>
          ),
        },
        {
          path: "checklist",
          children: [
            {
              path: "school",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <SchoolForm />
                </Suspense>
              ),
            },
            {
              path: "serviceDelivery",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <BeneficiaryService />
                </Suspense>
              ),
            },
            {
              path: "beneficiary",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <ChecklistBeneficiary />
                </Suspense>
              ),
            },
            {
              path: "district",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <ChecklistDistrict />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "ndd",
          children: [
            {
              path: "monitoring",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <MonitoringNDD />
                </Suspense>
              ),
            },
            {
              path: "post",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <PostNDD />
                </Suspense>
              ),
            },
            {
              path: "pre",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <PreNDD />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "auth",
          children: [
            {
              path: "login",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <Login />
                </Suspense>
              ),
            },
            {
              path: "register",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <Register />
                </Suspense>
              ),
            },
            {
              path: "forgotPassword",
              element: (
                <Suspense fallback={<CustomLoader />}>
                  <ForgotPassword />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "*",
          element: (
            <Suspense fallback={<CustomLoader />}>
              <ErrorPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "reports/denominators/home",
      element: (
        <Suspense fallback={<CustomLoader />}>
          <StateUserRoute>
            <DenominatorsLayout>
              <DenominatorsHome />
            </DenominatorsLayout>
          </StateUserRoute>
        </Suspense>
      ),
    },
    {
      path: "/admin",
      element: (
        <Suspense fallback={<CustomLoader />}>
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        </Suspense>
      ),
      children: [
        {
          path: "home",
          element: (
            <Suspense fallback={<CustomLoader />}>
              <AdminPanel />
            </Suspense>
          ),
        },
        {
          path: "upload-resources",
          element: (
            <Suspense fallback={<CustomLoader />}>
              <ResourceAdmin />
            </Suspense>
          ),
        },
        {
          path: "upload-drug-requirements",
          element: (
            <Suspense fallback={<CustomLoader />}>
              <DrugRequirements />
            </Suspense>
          ),
        },
        {
          path: "upload-denominators",
          element: (
            <Suspense fallback={<CustomLoader />}>
              <UploadDenominators />
            </Suspense>
          ),
        },
        {
          path: "upload-numerator",
          element: (
            <Suspense fallback={<CustomLoader />}>
              <UploadNumerators />
            </Suspense>
          ),
        },
        {
          path: "delete-uploads",
          element: (
            <Suspense fallback={<CustomLoader />}>
              <DeleteExcelUploads />
            </Suspense>
          ),
        },
        {
          path: "count-admin",
          element: (
            <Suspense fallback={<CustomLoader />}>
              <CountAdmin />
            </Suspense>
          ),
        },
      ],
    },
  ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  </React.StrictMode>
);
