import axios from "axios";

const API_URL = "https://api.anemiamuktbharat.info";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.baseURL = API_URL;

const API = {
  /*----------  General Use APIs  ----------*/

  getVisitorsCount: async () => {
    try {
      const response = await axios.get(`/count/visitorCount`);
      return await response.data.data;
    } catch (error) {
      return 0;
    }
  },

  getAnnouncementText: async () => {
    try {
      const response = await axios.post(`/auth/msg`, {
        message: "hi",
      });
      return await response.data;
    } catch (error) {
      return 0;
    }
  },

  getAllStates: async (queriedDate) => {
    try {
      const currentDate =
        queriedDate && queriedDate !== ""
          ? new Date(queriedDate.toString())
          : new Date();
      const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11, so add 1

      let selectedYear;
      if (currentMonth >= 4 && currentMonth <= 12) {
        selectedYear = currentDate.getFullYear();
      } else {
        selectedYear = currentDate.getFullYear() - 1;
      }

      const response = await axios.get(`/state/names?year=${selectedYear}`);
      return await response.data.data;
    } catch (error) {
      return [];
    }
  },

  getAllDistrictsByState: async (currentState, selectedYear) => {
    try {
      const response = await axios.get(
        `/state/districts?state=${currentState}&year=${selectedYear}`
      );
      return await response.data;
    } catch (error) {
      return [];
    }
  },

  checkIfStateUserExist: async (selectedState) => {
    try {
      const response = await axios.post(`/auth/checkStateLogin`, {
        state: selectedState,
      });

      return response.data;
    } catch (error) {
      return error.message;
    }
  },

  createStateUser: async (values) => {
    try {
      const response = await axios.post(`/auth/createStateUser`, values);
      return response.data;
    } catch (error) {
      return error.message;
    }
  },

  setStateUserPassword: async (values) => {
    try {
      const response = await axios.post(`/auth/setStatePassword`, values);
      return response.data;
    } catch (error) {
      return error.message;
    }
  },

  changeStateUserPassword: async (values) => {
    try {
      const response = await axios.post(`/auth/changePassword`, values);
      return response.data;
    } catch (error) {
      return error.message;
    }
  },

  fetchStateUserDetails: async (selectedState) => {
    try {
      const response = await axios.post(
        `/auth/stateUserDetails?state=${selectedState}`
      );
      return response.data;
    } catch (error) {
      return [];
    }
  },

  updateStateUserPassword: async (values) => {
    try {
      const response = await axios.post(`/auth/updateStateUser`, values);
      return response.data;
    } catch (error) {
      return error.message;
    }
  },

  /*=============================================
  =               Report APIs                   =
  =============================================*/

  /*----------  KPIQuarterly Page API Calls  ----------*/

  getKpiQuarterlyData: async ({ stateCountry, year, quarter, normalized }) => {
    try {
      const response = await axios.post(`/report/kpiQuarterly`, {
        stateCountry,
        year,
        quarter,
        normalized,
      });

      return response.data;
    } catch (error) {
      return [];
    }
  },

  getQuarterlyKPIMapData: async ({ year, quarter, normalized }) => {
    try {
      const response = await axios.post(`/report/kpiMapQuarterly`, {
        year: year,
        quarter: quarter,
        normalized: normalized,
      });

      return response.data.data;
    } catch (error) {
      return [];
    }
  },

  /*----------  KPIMonthly Page API Calls  ----------*/

  getKpiMonthlyData: async ({ stateCountry, month, year, normalized }) => {
    try {
      const response = await axios.post(`/report/kpiMonthly`, {
        stateCountry: stateCountry,
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        normalized,
      });
      return response.data;
    } catch (error) {
      return [];
    }
  },

  /*----------  Raw Data API Calls  ----------*/

  getDenominatorsData: async (selectedLocation, selectedYear, selectedType) => {
    try {
      const response = await axios.post(`/denopdf/states`, {
        stateCountry: selectedLocation,
        year: selectedYear,
        type: selectedType,
      });

      return response.data;
    } catch (error) {
      return false; // Indicate that an error occurred during the process
    }
  },

  getNumeratorData: async (selectedMonth, selectedYear, selectedLocation) => {
    try {
      const response = await axios.post(`/numerator/download`, {
        month: selectedMonth,
        year: selectedYear,
        stateCountry: selectedLocation,
      });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      return false; // Indicate that an error occurred during the process
    }
  },

  getDrugRequirementData: async (selectedYear) => {
    try {
      const response = await axios.post(`/drugRequirement/download`, {
        year: selectedYear.format("YYYY"),
      });

      if (response.data.data) {
        if (response.data.success === false) {
          return response.data.success;
        } else {
          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = response?.data?.data?.url;
          link.setAttribute(
            "download",
            `Denominators File: ${selectedYear}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);

          return true; // Indicate that the download was successful
        }
      } else {
        return "No Data";
      }
    } catch (error) {
      return false; // Indicate that an error occurred during the process
    }
  },

  /*=====  End of Report APIs  ======*/

  /*=============================================
  =            STOCK API CALLS                  =
  =============================================*/

  /*----------  Monthly  ----------*/

  getStockDataMonthly: async ({ startYear, location }) => {
    try {
      const response = await axios.post(`stock/stockMonthly`, {
        startYear,
        location,
      });

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  /*----------  Quarterly  ----------*/

  getStockDataQuarterly: async ({ startYear, location }) => {
    try {
      const response = await axios.post(`stock/stockQuarterly`, {
        startYear,
        location,
      });

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  /*=====  End of Stock APIs  ======*/

  /*=============================================
  =               Ranking APIs                 =
  =============================================*/

  getAspDistrictData: async (queryData) => {
    try {
      const response = await axios.post(`/ranking/aspDistrict`, {
        startYear: queryData.startYear,
        endYear: queryData.endYear,
        startMonth: queryData.startMonth,
        endMonth: queryData.endMonth,
        normalized: queryData.normalized,
      });

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  getAllDistrictData: async (queryData) => {
    try {
      const response = await axios.post(`/ranking/allDistrict`, {
        startYear: queryData.startYear,
        endYear: queryData.endYear,
        startMonth: queryData.startMonth,
        endMonth: queryData.endMonth,
        normalized: queryData.normalized,
      });

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  getRankingMonthlyData: async ({
    stateCountry,
    startMonth,
    startYear,
    endMonth,
    endYear,
    normalized,
  }) => {
    try {
      const response = await axios.post(`/ranking/ambRanking`, {
        stateCountry: stateCountry?.toUpperCase(),
        startYear,
        endYear,
        startMonth,
        endMonth,
        normalized,
      });

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  getMonthlyRankingMapData: async ({ month, year, normalized }) => {
    try {
      let normalizedQuery = normalized === true ? "True" : "False";
      const response = await axios.post(`/report/kpiMapMonthly`, {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        normalized: normalizedQuery,
      });

      return await response.data.data;
    } catch (error) {
      return [];
    }
  },

  /*----------  Cumulative APIs  ----------*/

  getCumulativeRankingMapData: async ({
    startMonth,
    startYear,
    endMonth,
    endYear,
    normalized,
  }) => {
    try {
      let normalizedQuery = normalized === true ? "True" : "False";
      const response = await axios.post(`/ranking/mapCumulative`, {
        startYear: parseInt(startYear, 10),
        endYear: parseInt(endYear, 10),
        startMonth: parseInt(startMonth, 10),
        endMonth: parseInt(endMonth, 10),
        normalized: normalizedQuery,
      });

      return await response.data.data;
    } catch (error) {
      return [];
    }
  },

  getRankingCumulativeData: async ({
    stateCountry,
    startMonth,
    startYear,
    endMonth,
    endYear,
    normalized,
  }) => {
    try {
      const response = await axios.post(`/ranking/ambRanking`, {
        stateCountry: stateCountry,
        startYear,
        endYear,
        startMonth,
        endMonth,
        normalized,
      });

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  /*=====  End of Ranking APIs  ======*/

  /*=============================================
  =            Maternal Nutrition APIs          =
  =============================================*/

  getMNDataMonthlyNonCumulative: async ({
    startYear,
    endYear,
    location,
    normalized,
  }) => {
    try {
      const response = await axios.post(
        `/maternalNutrition/monthlyNonCumulative`,
        {
          startYear,
          endYear,
          location,
          normalized,
        }
      );

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  getMNDataMonthlyCumulative: async ({
    startYear,
    endYear,
    location,
    normalized,
  }) => {
    try {
      const response = await axios.post(
        `/maternalNutrition/monthlyCumulative`,
        {
          startYear,
          endYear,
          location,
          normalized,
        }
      );

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  getMNDataQuarterlyNonCumulative: async ({
    startYear,
    endYear,
    location,
    normalized,
  }) => {
    try {
      const response = await axios.post(
        `/maternalNutrition/quarterlyNonCumulative`,
        {
          startYear,
          endYear,
          location,
          normalized,
        }
      );

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  getMNDataQuarterlyCumulative: async ({
    startYear,
    endYear,
    location,
    normalized,
  }) => {
    try {
      const response = await axios.post(
        `/maternalNutrition/quarterlyCumulative`,
        {
          startYear,
          endYear,
          location,
          normalized,
        }
      );

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  /*=====  End of Maternal Nutrition APIs  ======*/

  /*=============================================
  =                 Auth APIs                   =
  =============================================*/

  login: async (loginData) => {
    try {
      const response = await axios.post(`/auth/signin`, loginData);

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  denominatorsLogin: async (loginData) => {
    try {
      const response = await axios.post(`auth/stateLogin`, loginData);

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  register: async ({ name, email, empId, phoneNumber, password }) => {
    try {
      const response = await axios.post(`/auth/signup`, {
        name,
        email,
        empId,
        phoneNumber,
        password,
      });
      return await response.data;
    } catch (error) {
      return [];
    }
  },

  contactUs: async (values) => {
    try {
      const response = await axios.post(`/auth/contactUs`, { ...values });

      return response.data;
    } catch (error) {
      return [];
    }
  },

  generateOTP: async (email) => {
    try {
      const response = await axios.post(`/auth/genOtp`, { email: email });
      return response.data;
    } catch (error) {}
  },

  verifyOTP: async (email, verificationCode, verifyCodeId) => {
    try {
      const response = await axios.post(`/auth/verifyOtp`, {
        email: email,
        otp: verificationCode,
        verifyCodeId: verifyCodeId,
      });
      return response.data;
    } catch (error) {}
  },

  verifyCaptchaToken: async (token) => {
    try {
      let response = await axios.post(`/verify-captcha`, token);
      return response.data;
    } catch (error) {
      return [];
    }
  },

  resetPassword: async (email, newPassword) => {
    try {
      let response = await axios.post(`/auth/setpassword`, {
        email,
        password: newPassword,
      });

      return response.data;
    } catch (error) {
      return [];
    }
  },

  getCountStatic: async () => {
    try {
      const response = await axios.post(`count/static`, {});
      return await response.data.data;
    } catch (error) {
      return [];
    }
  },

  getCountStaticAdmin: async () => {
    try {
      const response = await axios.post(`count/admin`, {});
      return await response.data.data;
    } catch (error) {
      return [];
    }
  },

  getCountAdmin: async ({ year, month }) => {
    try {
      const response = await axios.post(`count/countData`, {
        year: year,
        month: month,
      });
      return await response.data.data;
    } catch (error) {
      return [];
    }
  },

  /*=====  End of Auth APIs  ======*/

  /*=============================================
  =              Resource APIs                  =
  =============================================*/

  getAllResources: async () => {
    try {
      const response = await axios.get(`/resources/page?_limit=2`);
      return await response.data.data;
    } catch (error) {
      return [];
    }
  },

  getAllResourcesByCategory: async (category) => {
    try {
      const response = await axios.post(`/resources/page/category`, {
        category: category,
      });
      return await response.data.data;
    } catch (error) {
      return [];
    }
  },

  increaseResourceDownloadCount: async (category, folderName, fileName) => {
    try {
      const response = await axios.post(`/count/resourceDownload`, {
        category,
        folderName,
        fileName,
      });

      return await response.data.data;
    } catch (error) {}
  },

  deleteResource: async (category, folderName, fileName) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        throw new Error("Access Token is missing");
      }

      const response = await axios.post(
        `/resources/delete/file`,
        {
          category,
          folderName,
          fileName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return await response.data.success;
    } catch (error) {
      return [];
    }
  },

  getAllCategories: async () => {
    try {
      const response = await axios.get(`/resources/allCategory`);
      return await response.data.data;
    } catch (error) {
      return [];
    }
  },

  getFoldersByCategory: async (category) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `/resources/category/${category}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return await response.data.data;
    } catch (error) {
      return [];
    }
  },

  /*=====  End of Resource APIs  ======*/

  /*=============================================
=            Admin APIs                       =
=============================================*/

  uploadResource: async (formData, category, folderName) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        throw new Error("Access Token is missing");
      }

      const queryParams = new URLSearchParams({
        category: category,
        folderName: folderName,
      }).toString();

      const response = await axios.post(
        `/resources/upload?${queryParams}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return [];
    }
  },

  uploadDenominator: async (selectedYear, formData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        throw new Error("Access Token is missing");
      }

      const response = await axios.post(
        `/denominator/upload?year=${selectedYear}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {}
  },

  getAllDenominators: async () => {
    try {
      const response = await axios.get(`/denominator/list`);

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  getAllNumerators: async () => {
    try {
      const response = await axios.get(`/numerator/list`);

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  getAllDrugRequirements: async () => {
    try {
      const response = await axios.get(`/drugRequirement/list`);

      return await response.data;
    } catch (error) {
      return [];
    }
  },

  uploadNumerator: async (selectedMonth, selectedYear, formData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        throw new Error("Access Token is missing");
      }

      const response = await axios.post(
        `/numerator/upload?month=${selectedMonth}&year=${selectedYear}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {}
  },

  uploadDrugRequirements: async (selectedYear, formData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        throw new Error("Access Token is missing");
      }

      const response = await axios.post(
        `/drugRequirement/upload?year=${selectedYear}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {}
  },

  deleteUploads: async (selectedYear) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        throw new Error("Access Token is missing");
      }

      const response = await axios.delete(
        `/denominator/delete?year=${selectedYear.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return await response.data;
    } catch (error) {}
  },

  /*=====  End of Admin APIs  ======*/

  /*=============================================
  =            Situation APIs            =
  =============================================*/

  fetchAnemiaSituationIndia: async (selectedNFHS) => {
    try {
      const response = await axios.post(
        `/anemiaPrevalence/national?nfhs=${selectedNFHS}`
      );

      return response.data;
    } catch (error) {
      return [];
    }
  },

  fetchAnemiaSituationGlobal: async (selectedYear) => {
    try {
      const response = await axios.post(
        `/anemiaPrevalence/global?year=${selectedYear}`
      );

      return response.data;
    } catch (error) {
      return [];
    }
  },

  fetchStateListByNFHS: async (selectedNFHS) => {
    try {
      const response = await axios.post(
        `anemiaPrevalence/stateList?nfhs=${selectedNFHS}`
      );

      return response.data;
    } catch (error) {
      return [];
    }
  },

  fetchAnemiaSituationState: async (values) => {
    try {
      const response = await axios.post(`/anemiaPrevalence/state`, values);

      return response.data;
    } catch (error) {
      return [];
    }
  },

  /*=====  End of Situation APIs  ======*/

  /*=====  Start of checkList ServiceDelivery APIs  ======*/

  postSeviceDeliveryChecklist: async (values) => {
    try {
      const response = await axios.post(`/checklist/serviceDelivery`, values);
      return response.data;
    } catch (error) {
      return error.message;
    }
  },

  /*=====  End of checkList ServiceDelivery APIs  ======*/
  /*=============================================
         =            Survey APIs          =
     =============================================*/

  submitSurvey: async (values) => {
    try {
      const response = await axios.post(`/checklist/beneficiary`, {
        ...values,
      });

      return response.data;
    } catch (error) {
      return [];
    }
  },

  /*=============================================
      =            Checklist district APIs          =
     =============================================*/

  submitChecklistDistrict: async (values) => {
    try {
      const response = await axios.post(`/checklist/district`, { ...values });

      return response.data;
    } catch (error) {
      return [];
    }
  },

  /*=============================================
      =            Checklist state APIs          =
     =============================================*/

  submitChecklistState: async (values) => {
    try {
      const response = await axios.post(`/checklist/state`, { ...values });

      return response.data;
    } catch (error) {
      return [];
    }
  },

  submitMonitoringNDD: async (values) => {
    console.log("API Service - submitting monitoring NDD with values:", values);
    try {
      const apiUrl = '/ndd/monitoring';
      console.log("Making POST request to:", apiUrl);

      const response = await axios.post(apiUrl, { ...values });
      console.log("API Response received:", response);

      return response.data;
    } catch (error) {
      console.error("API Error:", {
        message: error.message,
        response: error.response,
        status: error?.response?.status,
        data: error?.response?.data
      });
      throw error; // Re-throw to handle in component
    }
  },

  submitPostNDD: async (values) => {
    try {
      const response = await axios.post(`/ndd/post`, { ...values });

      return response.data;
    } catch (error) {
      return [];
    }
  },

  submitPreNDD: async (values) => {
    try {
      const response = await axios.post(`/ndd/pre`, { ...values });

      return response.data;
    } catch (error) {
      return [];
    }
  },
};

export default API;
