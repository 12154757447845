import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import API from "services/api";
import Loader from "utils/Loader";
import SnackBar from "utils/SnackBar";
import Form from "../../components/DefaultForm/Form";
import { monitoringNDDFields } from "./formFields";

const MonitoringNDD = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);

  const [formData, setFormData] = useState({
    latitude: "Fetching location...",
    longitude: "Fetching location...",
  });

  const [snackBarMessage, setSnackBarMessage] = useState({
    text: "",
    type: "",
  });

  const updatedFields = monitoringNDDFields.map(field => {
    if (field.name === 'latitude' || field.name === 'longitude') {
      return {
        ...field,
        value: formData[field.name],
        defaultValue: formData[field.name]
      };
    }
    return field;
  });

  console.log("Updated fields:", updatedFields);

  const fetchLocation = () => {
    if (navigator.geolocation) {
      setLoadingStatus(true);
      navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude.toFixed(6);
            const lng = position.coords.longitude.toFixed(6);
            console.log("Location fetched:", { lat, lng });

            setFormData(prevData => {
              const newData = {
                ...prevData,
                latitude: lat,
                longitude: lng,
              };
              console.log("Setting new formData:", newData);
              return newData;
            });

            setLoadingStatus(false);
          },
          (error) => {
            console.error("Geolocation error:", error);
            setFormData(prevData => ({
              ...prevData,
              latitude: "Location not available",
              longitude: "Location not available",
            }));
            setShowSnackBar(true);
            setSnackBarMessage({
              text: "Failed to get location. Please ensure location services are enabled.",
              type: "error",
            });
            setLoadingStatus(false);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }
      );
    } else {
      console.log("Geolocation not supported");
      setFormData(prevData => ({
        ...prevData,
        latitude: "Geolocation not supported",
        longitude: "Geolocation not supported",
      }));
      setShowSnackBar(true);
      setSnackBarMessage({
        text: "Geolocation is not supported by this browser.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  const handleData = async (submittedFormData) => {
    console.log("handleData called with:", submittedFormData);

    // Validate if we have all required fields
    const requiredFields = monitoringNDDFields.filter(field => field.required);
    const missingFields = requiredFields.filter(field => !submittedFormData[field.name]);

    if (missingFields.length > 0) {
      console.log("Missing required fields:", missingFields);
      setShowSnackBar(true);
      setSnackBarMessage({
        text: "Please fill all required fields",
        type: "error",
      });
      return;
    }

    try {
      console.log("Making API call to /ndd/monitoring with payload:", submittedFormData);
      setLoadingStatus(true);

      const response = await API.submitMonitoringNDD(submittedFormData);
      console.log("API Response:", response);

      if (response.success) {
        setShowSnackBar(true);
        setSnackBarMessage({
          text: "Form submitted successfully!",
          type: "success",
        });
      } else {
        throw new Error("API returned success: false");
      }
    } catch (error) {
      console.error("API call failed:", error);
      setShowSnackBar(true);
      setSnackBarMessage({
        text: "Failed to submit form. Please try again later.",
        type: "error",
      });
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleCloseSnackBar = () => {
    setShowSnackBar(false);
  };

  const formKey = JSON.stringify(formData);

  return (
      <>
        <SnackBar
            message={snackBarMessage.text}
            open={showSnackBar}
            handleClose={handleCloseSnackBar}
            type={snackBarMessage.type}
        />
        <div className="contact-main-container container-fluid">
          {loadingStatus && <Loader loading={loadingStatus} />}
          <Form
              key={formKey}
              initialValues={formData}
              formHeading="Monitoring NDD"
              fields={updatedFields}
              title="Submit"
              title2="Save Draft"
              secondIcon={<EditOutlinedIcon fontSize="8px" />}
              firstIcon={<DoneIcon fontSize="8px" />}
              onClick={(data) => {
                console.log("Form onClick triggered with data:", data);
                handleData(data);
              }}
              isShowCancel={false}
          />
        </div>
      </>
  );
};

export default MonitoringNDD;
