import React, { useState, useEffect } from "react";
import API from "services/api";

import styles from "./styles.module.css";
import "./trainings.css";

import { Link } from "react-router-dom";
import { linksArray } from "data/homeLinks";
import { Button, Modal } from "react-bootstrap";
// import Announcement from "react-announcement";
import ChecklistCodeInput from "components/checklist/ChecklistCodeInput";
import Ticker from "components/shared/Ticker";

const Home = () => {
  const [visitors, setVisitorsCount] = useState(0);

  const [isAmbChecklistOpen, setIsAmbChecklistOpen] = useState(false);
  const [isNddChecklistOpen, setIsNddChecklistOpen] = useState(false);
  const [checklistCode, setChecklistCode] = useState();
  const [announcementText, setAnnouncementText] = useState(" Welcome to AMB Dashboard! **LATEST UPDATES** IFA Coverage available till month of Oct 2024. NDD Checklist available in Home page.");

  const toggleCheckList = () => {
    setIsAmbChecklistOpen(!isAmbChecklistOpen);
    setChecklistCode();
  };

  const toggleNDDCheckList = () => {
    setIsNddChecklistOpen(!isNddChecklistOpen);
    setChecklistCode();
  };

  useEffect(() => {
    async function getVisitorCount() {
      try {
        const response = await API.getVisitorsCount();
        setVisitorsCount(response);
      } catch (error) {
        setVisitorsCount(0);
      }
    }

    // async function getAnnouncementText() {
    //   try {
    //     const response = await API.getAnnouncementText();
    //     setAnnouncementText(response.message);
    //   } catch (error) {
    //     setAnnouncementText("");
    //   }
    // }

    // getAnnouncementText();
    getVisitorCount();
  }, []);

  const handleCodeChange = (value) => {
    setChecklistCode(value);
  };

  return (
    <>
          <Ticker text={announcementText} />
      <div className="visiter-count">Visitors Count: {visitors}</div>{" "}
      <div className={styles.heroSection}>
        <div className={styles.heroContent} style={{ textAlign: "center" }}>
          <h5>One Stop Portal for Reporting, Monitoring and Review</h5>
          <br />
          <br />
          <h3>Anemia Mukt Bharat</h3>
          <h1>Dashboard</h1>

          <br />
          <div className="links-container">
            <div className="training-link">
              <a
                href="https://anemiamuktbharatelearning.org/"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#014473" }}
              >
                AMB e-Training Module
              </a>

              <div
                className="checklist"
                onClick={toggleCheckList}
                style={{ color: "#014473" }}
              >
                AMB Checklist
              </div>

              <div
                className="checklist"
                onClick={toggleNDDCheckList}
                style={{ color: "#014473" }}
              >
                NDD Checklist
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.aboutSection}>
          <div className={styles.aboutSectionLeft}>
            <h4 className={styles.aboutSectionHeader}>About AMB Dashboard</h4>
            <p>
              Welcome to Anemia Mukt Bharat Dashboard. AMB Dashboard is a
              one-stop shop for information on anemia reduction interventions
              and presents key performance indicators (KPI) in an interactive
              manner for State, district and block level. It uses data
              visualization tools such as maps, bar graphs, charts and index for
              programme review and progress assessment.
            </p>
          </div>

          <div className={styles.aboutSectionRight}>
            <h4 className={styles.aboutSectionHeader}>
              Key Features of AMB Dashboard
            </h4>
            <div className={styles.cards}>
              {linksArray.map((link, index) => (
                <Link key={index} to={link.to} className={styles.card}>
                  <span>
                    <img
                      src={link.icon}
                      alt={link.alt}
                      className={styles.icon}
                    />
                  </span>
                  <div className={styles.cardTitle}>{link.cardTitle}</div>
                  <div className={styles.cardBody}>{link.cardBody}</div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <>
        <div className="social-logos">
          <a
            href="https://twitter.com/i/flow/login?redirect_after_login=%2Famb_ieg"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://res.cloudinary.com/daxilgrvn/image/upload/v1690274523/twitter_red-removebg-preview_j859ak.png"
              alt="twitter-logo"
            />
          </a>

          <a
            href="https://www.youtube.com/channel/UCBcME2WtmOXkaSOSZratYhw"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://res.cloudinary.com/daxilgrvn/image/upload/v1690274522/youtube-removebg-preview_mybg28.png"
              alt="youtube-logo"
            />
          </a>
        </div>
      </>
      <Modal show={isAmbChecklistOpen} onHide={toggleCheckList}>
        <Modal.Header closeButton>
          <Modal.Title>AMB Checklist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>1. AMB Checklist_State:{" "}
              <a className="modal-link" href="/checklist/state" target="_blank" rel="noreferrer">
                Link
              </a>
            </p>

            <p>2. AMB Checklist_Service Delivery Point:{" "}
              <a className="modal-link" href="/checklist/serviceDelivery" target="_blank" rel="noreferrer">
                Link
              </a>
            </p>

            <p>3. AMB Checklist_School:{" "}
              <a className="modal-link" href="/checklist/school" target="_blank" rel="noreferrer">
                Link
              </a>
            </p>

            <p>4. AMB Checklist_District:{" "}
              <a className="modal-link" href="/checklist/district" target="_blank" rel="noreferrer">
                Link
              </a>
            </p>

            <p>5. AMB Checklist_Beneficiaries:{" "}
              <a className="modal-link" href="/checklist/beneficiary" target="_blank" rel="noreferrer">
                Link
              </a>
            </p>
          </>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={toggleCheckList}
            style={{ backgroundColor: "#069edb", borderColor: "#069edb" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isNddChecklistOpen} onHide={toggleNDDCheckList}>
        <Modal.Header closeButton>
          <Modal.Title>NDD Checklist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>1. NDD Monitoring Checklist:{" "}
              <a className="modal-link" href="https://ee.kobotoolbox.org/i/SS0JWWbo" target="_blank" rel="noreferrer">
                Link
              </a>
            </p>

            <p>2. NDD Post Checklist:{" "}
              <a className="modal-link" href="https://ee.kobotoolbox.org/i/rvO2gWfk" target="_blank" rel="noreferrer">
                Link
              </a>
            </p>

            <p>3. NDD Pre Checklist:{" "}
              <a className="modal-link" href="https://ee.kobotoolbox.org/i/pmFGYOcG" target="_blank" rel="noreferrer">
                Link
              </a>
            </p>
          </>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={toggleNDDCheckList}
            style={{ backgroundColor: "#069edb", borderColor: "#069edb" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
