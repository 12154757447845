import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import "./header.css";

import logo from "assets/img/LogoAmb.png";
import emblem from "assets/img/emblem.png";
import poshan from "assets/img/poshan.png";
import nhm from "assets/img/NHM-logo.png";
import { useAuth } from "context/AuthContext";

const HomeHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const { stateToken } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerClassName = `amb__header-sticky ${
    isScrolled ? "hide-on-scroll" : ""
  }`;

  const navbarClassName = `amb__navbar sticky-top shadow-sm ${
    isScrolled ? "scrolled" : ""
  }`;
  const navbarColor = `basic-navbar-nav ${isScrolled ? "scrolled" : ""}`;

  return (
    <header id="fixed-header-home">
      <div className={headerClassName}>
        <div className="middle-logo">
          <a href="https://nhm.gov.in/" target="_blank" rel="noreferrer">
            <img
              className="amb__sh-nhm"
              src={nhm}
              alt="National Health Mission"
            />
          </a>
          <a
            href="https://poshanabhiyaan.gov.in/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="amb__sh-poshan" src={poshan} alt="Poshan" />
          </a>
        </div>

        <a href="https://www.mohfw.gov.in/" target="_blank" rel="noreferrer">
          <img className="amb__sh-emblem" src={emblem} alt="emblem" />
        </a>
        <Link to="/">
          <img className="amb__sh-logo" src={logo} alt="Logo" />
        </Link>
      </div>

      <Navbar className={navbarClassName} expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className={navbarColor}>
          <Nav className="navbar-main">
            <Nav.Link href="/">Home</Nav.Link>

            <NavDropdown title="Programme" id="basic-nav-dropdown">
              <NavDropdown.Item href="/programme/about">About</NavDropdown.Item>
              {/* <NavDropdown.Item href="/programme/targets">
                Target
              </NavDropdown.Item> */}
              <NavDropdown.Item href="/programme/6x6x6-strategy">
                6x6x6 Strategy
              </NavDropdown.Item>
              <NavDropdown.Item href="/programme/beneficiaries">
                Beneficiaries
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Anemia Situation" id="basic-nav-dropdown">
              <NavDropdown.Item href="/anemia-situation/global">
                Global Situation
              </NavDropdown.Item>
              <NavDropdown.Item href="/anemia-situation/india">
                India Situation
              </NavDropdown.Item>
              <NavDropdown.Item href="/anemia-situation/state">
                State Situation
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="IFA Supplementation" id="basic-nav-dropdown">
              <NavDropdown.Item href="/reports/ranking">
                AMB Ranking
              </NavDropdown.Item>
              <NavDropdown.Item href="/reports/key-performance-indicators">
                AMB Performance Indicators
              </NavDropdown.Item>
              {/*<NavDropdown.Item href="/reports/testsTreatment">*/}
              {/*  Tests and Treatments*/}
              {/*</NavDropdown.Item>*/}
              <NavDropdown.Item href="/reports/stock">Stock</NavDropdown.Item>
              <NavDropdown.Item
                href={
                  stateToken
                    ? "/reports/denominators/home"
                    : "/reports/denominators/login"
                }
              >
                Denominators
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/resources/home">Resources</Nav.Link>

            <Nav.Link href="/contact/home">Contact Us</Nav.Link>

            {/* <NavDropdown title="Stock">
              <NavDropdown title="Monthly" drop="end">
                <NavDropdown.Item href="/stock/monthly/cumulative">
                  Monthly Progress
                  <br /> Report (Cumulative)
                </NavDropdown.Item>
                <NavDropdown.Item href="/stock/monthly/non-cumulative">
                  Monthly Progress
                  <br /> Report (Non-Cumulative)
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Quarterly" drop="end">
                <NavDropdown.Item href="/stock/quarterly/cumulative">
                  Quarterly Progress
                  <br /> Report (Cumulative)
                </NavDropdown.Item>
                <NavDropdown.Item href="/stock/quarterly/non-cumulative">
                  Quarterly Progress
                  <br /> Report (Non-Cumulative)
                </NavDropdown.Item>
              </NavDropdown>
            </NavDropdown> */}

            {/* <NavDropdown title="AMB Ranking" id="basic-nav-dropdown">
              <NavDropdown.Item href="/ranking/monthly">
                Month-wise
              </NavDropdown.Item>
              <NavDropdown.Item href="/ranking/quarterly">
                Quarter-wise
              </NavDropdown.Item>
            </NavDropdown> */}

            {/* 
            <NavDropdown title="Maternal Nutrition" id="basic-nav-dropdown">
              <NavDropdown title="Quarterly" drop="end">
                <NavDropdown.Item href="/maternal-nutrition/quarterly/progress-report/cumulative">
                  Quarterly Progress
                  <br /> Report (Cumulative)
                </NavDropdown.Item>
                <NavDropdown.Item href="/maternal-nutrition/quarterly/progress-report/non-cumulative">
                  Quarterly Progress
                  <br /> Report (Non-Cumulative)
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Monthly" drop="end">
                <NavDropdown.Item href="/maternal-nutrition/monthly/progress-report/cumulative">
                  Monthly Progress
                  <br /> Report (Cumulative)
                </NavDropdown.Item>
                <NavDropdown.Item href="/maternal-nutrition/monthly/progress-report/non-cumulative">
                  Monthly Progress
                  <br /> Report (Non-Cumulative)
                </NavDropdown.Item>
              </NavDropdown>
            </NavDropdown> */}

            {/* <NavDropdown title="Contact Us" id="basic-nav-dropdown">
              <NavDropdown.Item href="/contact/partners/institutes">
                Partner Institutes
              </NavDropdown.Item>
              <NavDropdown.Item href="/contact/home">
                Contact Us
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default HomeHeader;
